import React from "react";
import Footer from "@/components/footer";
import Layout from "@/components/layout";
import ContentSucesso from "@/components/404Content";
import MenuContextProvider from "@/context/menu-context";
import SearchContextProvider from "@/context/search-context";
import HeaderOne from "@/components/header-two";

const NotFoundPage = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="404: Not found">
          <HeaderOne />
          <ContentSucesso />
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default NotFoundPage;

